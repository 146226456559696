<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h2>
          <v-icon class="pr-5" color="blue">devices</v-icon>
          Mijn apparaten
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <template v-slot:default>
            <thead>
            <tr>
              <th width="100">Acties</th>
              <th>Naam</th>
              <th>DevEUI</th>
              <th>Alarmeringen</th>
              <th class="hidden-sm-and-down">Type</th>
              <th class="hidden-sm-and-down">Status</th>
              <th width="50"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="device in devices" :key="device.name">
              <td>
                <v-btn link small :to="{name:'device-details', params: { deviceId: device.id}}">
                  Bewerk
                </v-btn>
              </td>
              <td>{{ device.name }}</td>
              <td><code>{{ device.devEui }}</code></td>
              <td>
                <span v-if="device.alarmValue !== null"><strong>{{ device.alarmValue }}</strong> graden: <strong>{{ device.alarmPhoneNumbers }}</strong></span>
                <span v-else>Geen</span>
              </td>
              <td class="hidden-sm-and-down">{{ device.type }}</td>
              <td class="hidden-sm-and-down">
                <v-chip label outlined :color="deviceStatusChip(device.status)">{{ device.status.toLowerCase() }}</v-chip>
              </td>
              <td>
                <v-btn small icon @click="deleteDevice(device.id)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import DevicesApi from '@/api/DevicesApi'
import { createFlashMessage } from '@/stores/utils'

export default {
  data: () => ({}),
  computed: {
    devices () {
      return this.$store.getters['deviceStore/devices']
    },
  },
  methods: {
    deviceStatusChip (status) {
      switch (status) {
      case 'ACTIVE':
        return 'success'
      case 'ALARMED':
        return 'warning'
      case 'INACTIVE':
        return 'primary'
      default:
        return 'default'
      }
    },
    deleteDevice (deviceId) {
      if (confirm('Weet je dit zeker?')) {
        DevicesApi.delete(deviceId)
          .then(res => {
            // reload store and show flash message
            if (res.result) {
              this.$store.dispatch('deviceStore/load')
              const flash = createFlashMessage({ type: 'warning', message: 'Apparaat verwijderd' })
              this.$store.dispatch('globalStore/addFlashMessage', flash)
            }
          })
          .catch(err => this.$store.dispatch('globalStore/addFlashMessage',
            createFlashMessage({ type: 'error', message: err }))
          )
      }
    }
  }
}
</script>
